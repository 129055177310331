@import "src/styles/shared.scss";

/*$timeline-color-1: #ec008c;
$timeline-color-2: #76ced9;
$timeline-color-3: #92278f;
$timeline-color-4: #f06793;
$timeline-color-5: #560089;*/

$timeline-color-1: #eb008c;
$timeline-color-2: #76cdd9;
$timeline-color-3: #862996;
$timeline-color-4: #fd659a;
$timeline-color-5: #550088;

$timeline-color-text: #ffffff;

.vertical-timeline-modal
{
    h4
    {
        font-weight: bold;
        margin-bottom: $spacer * 2;
    }

    .lead
    {
        font-weight: bold;
    }

    .vertical-timeline-modal-reference
    {
        li
        {
            span
            {
                color: $body-color;
            }
        }
    }

    > *
    {
        &:last-child
        {
            > *
            {
                z-index: 2;
                position: relative;

                &:last-child
                {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}

.vertical-timeline-year-item
{
    > *
    {
        &:last-child
        {
            margin-bottom: 0 !important;
        }
    }
}

.vertical-timeline-image-color-1
{
    > div
    {
        > div
        {
            background: $timeline-color-1 !important;
        }

        &:after
        {
            background: rgba($timeline-color-1, 0.4) !important;
        }
    }
}

.vertical-timeline-image-color-2
{
    > div
    {
        > div
        {
            background: $timeline-color-2 !important;
        }

        &:after
        {
            background: rgba($timeline-color-2, 0.4) !important;
        }
    }
}

.vertical-timeline-image-color-3
{
    > div
    {
        > div
        {
            background: $timeline-color-3 !important;
        }

        &:after
        {
            background: rgba($timeline-color-3, 0.4) !important;
        }
    }
}

.vertical-timeline-image-color-4
{
    > div
    {
        > div
        {
            background: $timeline-color-4 !important;
        }

        &:after
        {
            background: rgba($timeline-color-4, 0.4) !important;
        }
    }
}

.vertical-timeline-image-color-5
{
    > div
    {
        > div
        {
            background: $timeline-color-5 !important;
        }

        &:after
        {
            background: rgba($timeline-color-5, 0.4) !important;
        }
    }
}

.vertical-timeline-modal--color-1
{
    background: $timeline-color-1;

    h4
    {
        color: $timeline-color-1;
    }

    .vertical-timeline-modal-reference
    {
        color: $timeline-color-1;
    }

    .vertical-timeline-video
    {
        .vertical-timeline-video-preview
        {
            &:before
            {
                background: $timeline-color-1;
            }

            &:after
            {
                background: rgba($timeline-color-1, 0.4);
            }
        }
    }

    .vertical-timeline-circle-image
    {

        > div
        {
            > div
            {
                background: $timeline-color-1;
            }

            &:after
            {
                background: rgba($timeline-color-1, 0.4);
            }
        }
    }
}

.vertical-timeline-modal--color-2
{
    background: $timeline-color-2;

    h4
    {
        color: $timeline-color-2;
    }

    .vertical-timeline-modal-reference
    {
        color: $timeline-color-2;
    }

    .vertical-timeline-video
    {
        .vertical-timeline-video-preview
        {
            &:before
            {
                background: $timeline-color-2;
            }

            &:after
            {
                background: rgba($timeline-color-2, 0.4);
            }
        }
    }

    .vertical-timeline-circle-image
    {
        > div
        {
            > div
            {
                background: $timeline-color-2;
            }

            &:after
            {
                background: rgba($timeline-color-2, 0.4);
            }
        }
    }
}

.vertical-timeline-modal--color-3
{
    background: $timeline-color-3;

    h4
    {
        color: $timeline-color-3;
    }

    .vertical-timeline-modal-reference
    {
        color: $timeline-color-3;
    }

    .vertical-timeline-video
    {
        .vertical-timeline-video-preview
        {
            &:before
            {
                background: $timeline-color-3;
            }

            &:after
            {
                background: rgba($timeline-color-3, 0.4);
            }
        }
    }

    .vertical-timeline-circle-image
    {
        > div
        {
            > div
            {
                background: $timeline-color-3;
            }

            &:after
            {
                background: rgba($timeline-color-3, 0.4);
            }
        }
    }
}

.vertical-timeline-modal--color-4
{
    background: $timeline-color-4;

    h4
    {
        color: $timeline-color-4;
    }

    .vertical-timeline-modal-reference
    {
        color: $timeline-color-4;
    }

    .vertical-timeline-video
    {
        .vertical-timeline-video-preview
        {
            &:before
            {
                background: $timeline-color-4;
            }

            &:after
            {
                background: rgba($timeline-color-4, 0.4);
            }
        }
    }

    .vertical-timeline-circle-image
    {
        > div
        {
            > div
            {
                background: $timeline-color-4;
            }

            &:after
            {
                background: rgba($timeline-color-4, 0.4);
            }
        }
    }
}

.vertical-timeline-modal--color-5
{
    background: $timeline-color-5;

    h4
    {
        color: $timeline-color-5;
    }

    .vertical-timeline-modal-reference
    {
        color: $timeline-color-5;
    }

    .vertical-timeline-video
    {
        .vertical-timeline-video-preview
        {
            &:before
            {
                background: $timeline-color-5;
            }

            &:after
            {
                background: rgba($timeline-color-5, 0.4);
            }
        }
    }

    .vertical-timeline-circle-image
    {
        > div
        {
            > div
            {
                background: $timeline-color-5;
            }

            &:after
            {
                background: rgba($timeline-color-5, 0.4);
            }
        }
    }
}

.vertical-timeline-year-item a {
    color: white !important;
}
.vertical-timeline-year-item ul {
    color: #212529 !important;
    font-size: 1rem !important;
}


.vertical-timeline-year-item {

    .content-link-color-1 {
        color: $timeline-color-1 !important;
    }

    .content-link-color-2 {
        color: $timeline-color-2 !important;
    }

    .content-link-color-3 {
        color: $timeline-color-3 !important;
    }

    .content-link-color-4 {
        color: $timeline-color-4 !important;
    }

    .content-link-color-5 {
        color: $timeline-color-5 !important;
    }
}

.vertical-timeline-element
{
    .vertical-timeline-element-content
    {
        .vertical-timeline-year
        {
            .vertical-timeline-year-heading
            {
                h2
                {
                    color: $timeline-color-text;
                }
            }

            .vertical-timeline-year-item
            {
                color: $timeline-color-text;
            }
        }
    }

    &.vertical-timeline-element--color-1
    {
        .vertical-timeline-element-icon
        {
            &:before
            {
                background: $timeline-color-1;
            }

            &:after
            {
                background: rgba($timeline-color-1, 0.4);
            }
        }

        .vertical-timeline-element-content
        {
            .vertical-timeline-year
            {

                .vertical-timeline-year-arrow
                {
                    svg
                    {
                        .vertical-timeline-year-pointer
                        {
                            fill: $timeline-color-1;
                            stroke: $timeline-color-1;
                        }
                    }
                }

                .vertical-timeline-year-heading
                {
                    background: $timeline-color-1;
                }

                .vertical-timeline-year-item
                {
                    background: $timeline-color-1;
                    border-bottom-color: lighten($timeline-color-1, 20%);
                }
            }
        }
    }

    &.vertical-timeline-element--color-2
    {
        .vertical-timeline-element-icon
        {
            &:before
            {
                background: $timeline-color-2;
            }

            &:after
            {
                background: rgba($timeline-color-2, 0.4);
            }
        }

        .vertical-timeline-element-content
        {
            .vertical-timeline-year
            {

                .vertical-timeline-year-arrow
                {
                    svg
                    {
                        .vertical-timeline-year-pointer
                        {
                            fill: $timeline-color-2;
                            stroke: $timeline-color-2;
                        }
                    }
                }

                .vertical-timeline-year-heading
                {
                    background: $timeline-color-2;
                }

                .vertical-timeline-year-item
                {
                    background: $timeline-color-2;
                    border-bottom-color: lighten($timeline-color-2, 20%);
                }
            }
        }
    }

    &.vertical-timeline-element--color-3
    {

        .vertical-timeline-element-icon
        {
            &:before
            {
                background: $timeline-color-3;
            }

            &:after
            {
                background: rgba($timeline-color-3, 0.4);
            }
        }

        .vertical-timeline-element-content
        {
            .vertical-timeline-year
            {

                .vertical-timeline-year-arrow
                {
                    svg
                    {
                        .vertical-timeline-year-pointer
                        {
                            fill: $timeline-color-3;
                            stroke: $timeline-color-3;
                        }
                    }
                }

                .vertical-timeline-year-heading
                {
                    background: $timeline-color-3;
                }

                .vertical-timeline-year-item
                {
                    background: $timeline-color-3;
                    border-bottom-color: lighten($timeline-color-3, 20%);
                }
            }
        }
    }

    &.vertical-timeline-element--color-4
    {

        .vertical-timeline-element-icon
        {
            &:before
            {
                background: $timeline-color-4;
            }

            &:after
            {
                background: rgba($timeline-color-4, 0.4);
            }
        }

        .vertical-timeline-element-content
        {
            .vertical-timeline-year
            {

                .vertical-timeline-year-arrow
                {
                    svg
                    {
                        .vertical-timeline-year-pointer
                        {
                            fill: $timeline-color-4;
                            stroke: $timeline-color-4;
                        }
                    }
                }

                .vertical-timeline-year-heading
                {
                    background: $timeline-color-4;
                }

                .vertical-timeline-year-item
                {
                    background: $timeline-color-4;
                    border-bottom-color: lighten($timeline-color-4, 20%);
                    
                    &.actionable
                    {
                        &:hover
                        {
                            background: darken($timeline-color-4, 50%);
                        }
                    }
                }
            }
        }
    }

    &.vertical-timeline-element--color-5
    {

        .vertical-timeline-element-icon
        {
            &:before
            {
                background: $timeline-color-5;
            }

            &:after
            {
                background: rgba($timeline-color-5, 0.4);
            }
        }

        .vertical-timeline-element-content
        {
            .vertical-timeline-year
            {

                .vertical-timeline-year-arrow
                {
                    svg
                    {
                        .vertical-timeline-year-pointer
                        {
                            fill: $timeline-color-5;
                            stroke: $timeline-color-5;
                        }
                    }
                }

                .vertical-timeline-year-heading
                {
                    background: $timeline-color-5;
                }

                .vertical-timeline-year-item
                {
                    background: $timeline-color-5;
                    border-bottom-color: lighten($timeline-color-5, 20%);

                    &.actionable
                    {
                        &:hover
                        {
                            background-color: darken($timeline-color-5, 10%);
                        }
                    }
                }
            }
        }
    }
}