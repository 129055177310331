@import "src/components/timeline/TimelineVariables.scss";

.vertical-timeline
{
    width: 100%;
    position: relative;
    padding: $vertical-timeline-padding-y $vertical-timeline-padding-x;
    overflow: hidden;
    overflow-x: hidden;
    padding-left: 16px;
    padding-right: 16px;

    @media only screen and (min-width: $vertical-timeline-md-breakpoint)
    {
        overflow: visible;
        overflow-x: hidden;
    }
}

/*.vertical-timeline::after
{
    content: '';
    display: table;
    clear: both;
}*/

.vertical-timeline::before
{
    /* this is the vertical line */
    content: '';
    position: absolute;
    top: 0;
    left: $vertical-timeline-spacer;
    height: 100%;
    width: $vertical-timeline-width;
    background: $vertical-timeline-color;

    @media only screen and (min-width: $vertical-timeline-md-breakpoint)
    {
        left: 50%;
        margin-left: -($vertical-timeline-width / 2);
    }
}

/*@media only screen and (min-width: 1170px)
{
    .vertical-timeline
    {
        margin-top: 3em;
        margin-bottom: 3em;
        width: 90%;
    }

    .vertical-timeline:before
    {
        left: 50%;
        margin-left: -2px;
    }
}*/