@import "src/components/timeline/TimelineVariables.scss";

.vertical-timeline-element
{
    position: relative;
    margin: $vertical-timeline-item-margin-y $vertical-timeline-item-margin-x;

    .vertical-timeline-element-icon
    {
        position: absolute;
        top: $vertical-timeline-icon-top;
        left: $vertical-timeline-icon-left;
        width: $vertical-timeline-icon-size;
        height: $vertical-timeline-icon-size;
        z-index: 10;
        //box-shadow: 0 0 0 $vertical-timeline-icon-glow-size rgba($vertical-timeline-icon-default-color, 0.4);
        &:before
        {
            content: '';
            display: block;
            z-index: 3;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background: $vertical-timeline-icon-default-color;
        }

        &:after
        {
            content: '';
            opacity: 0;
            display: block;
            z-index: 2;
            position: relative;
            border-radius: 50%;
            width: 300%;
            height: 300%;
            margin-left: -100%;
            margin-top: -100%;
            background: rgba($vertical-timeline-icon-default-color, 0.4);
        }

        &.is-hidden
        {
            visibility: hidden;
        }

        &.bounce-in
        {
            visibility: visible;

            &:before
            {
                animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s;
            }

            &:after
            {
                animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }

    .vertical-timeline-element-content
    {
        position: relative;
        margin-left: $vertical-timeline-content-xs-spacing;
        margin-bottom: $spacer;
        z-index: 5;


        .vertical-timeline-year
        {
            position: relative;

            .vertical-timeline-year-arrow
            {
                position: absolute;
                right: 100%;
                left: auto;
                top: 0;
                transform: matrix(-1, 0, 0, 1, 0, 0);

                svg
                {
                    width: 36px;
                    height: 12px;
                    vertical-align: top;

                    .vertical-timeline-year-pointer
                    {
                        fill: $vertical-timeline-content-background;
                        stroke: $vertical-timeline-content-background;
                        stroke-width: 1px;
                    }
                }
            }

            .vertical-timeline-year-heading
            {
                padding: $vertical-timeline-content-padding-y $vertical-timeline-content-padding-x;
                margin-bottom: $vertical-timeline-content-section-spacing;
                border-top-right-radius: $vertical-timeline-content-border-radius;
                background: $vertical-timeline-content-background;

                h2
                {
                    font-family: $vertical-timeline-content-header-font-family;
                    font-size: $vertical-timeline-content-header-font-size;
                    color: $vertical-timeline-content-color;
                    padding: 0 !important;
                    margin: 0 !important;
                }
            }

            .vertical-timeline-year-item
            {
                color: $vertical-timeline-content-color;
                font-size: $vertical-timeline-content-font-size;
                font-family: $vertical-timeline-content-font-family;
                background: $vertical-timeline-content-background;
                border-bottom-style: solid;
                border-bottom-width: $vertical-timeline-content-section-spacing;
                line-height: $vertical-timeline-content-line-height;
                width: 100%;

                &.actionable
                {
                    cursor: pointer;
                    padding-right: $vertical-timeline-content-padding-x * 2;
                    position: relative;


                    &:after
                    {
                        position: absolute;
                        font-family: 'FontAwesome';
                        content: '\f08e';
                        display: block;
                        right: $vertical-timeline-content-padding-x * 0.5;
                        top: $vertical-timeline-content-padding-y;
                        line-height: 1;
                    }

                    @media only screen and (min-width: $vertical-timeline-md-breakpoint)
                    {
                        padding-right: $vertical-timeline-content-padding-x;

                        transition: transform 0.5s;

                        &:after
                        {
                            display: none;
                        }

                        
                    }
                }

                &:last-child
                {
                    border-bottom-color: transparent !important;
                    border-bottom-left-radius: $vertical-timeline-content-border-radius;
                    border-bottom-right-radius: $vertical-timeline-content-border-radius;
                }
            }
        }

        &.is-hidden
        {
            visibility: hidden;
        }

        &.bounce-in
        {
            visibility: visible;
            animation: content-bounce 1.25s;
        }
    }

    .vertical-timeline-element-content:hover + .vertical-timeline-element-icon
    {
        &.bounce-in
        {
            &:before
            {
                animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
            }

            &:after
            {
                animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
            }
        }
    }

    @media only screen and (min-width: $vertical-timeline-md-breakpoint)
    {
        min-height: 4rem;

        .vertical-timeline-element-icon
        {
            left: 50%;
            margin-left: -($vertical-timeline-icon-size / 2);
        }

        .vertical-timeline-element-content
        {
            width: 45%;
            margin-left: 0;
            margin-bottom: $spacer * 4;
        }

        &:nth-child(even)
        {
            .vertical-timeline-element-content
            {
                float: left;

                .vertical-timeline-year-heading
                {
                    border-top-right-radius: 0;
                    border-top-left-radius: $vertical-timeline-content-border-radius;
                }

                .vertical-timeline-year
                {
                    position: relative;

                    .vertical-timeline-year-arrow
                    {
                        position: absolute;
                        right: auto;
                        left: 100%;
                        top: 0;
                        transform: none;
                    }
                }

                &.bounce-in
                {
                    visibility: visible;
                    animation: content-bounce-inverse 1.25s;
                }
            }

            &:after
            {
                content: '';
                display: block;
                clear: right;
            }
        }

        &:nth-child(odd)
        {
            .vertical-timeline-element-content
            {
                float: right;
            }

            &:after
            {
                content: '';
                display: block;
                clear: left;
            }
        }
    }
}

@keyframes pulse-ring
{
    0%
    {
        opacity: 1;
        transform: scale(.33);
    }

    80%, 100%
    {
        opacity: 0;
    }
}

@keyframes pulse-dot
{
    0%
    {
        transform: scale(.8);
    }

    50%
    {
        transform: scale(1);
    }

    100%
    {
        transform: scale(.8);
    }
}

@keyframes bounce-dot
{
    0%
    {
        opacity: 0;
        transform: scale(0.5);
    }

    60%
    {
        opacity: 1;
        transform: scale(1.2);
    }

    100%
    {
        transform: scale(1);
    }
}

@keyframes bounce-ring
{
    0%
    {
        opacity: 0;
        transform: scale(0.5);
    }

    60%
    {
        opacity: 1;
        transform: scale(1.2);
    }

    100%
    {
        opacity: 0;
        transform: scale(1);
    }
}

@keyframes content-bounce-inverse
{
    0%
    {
        opacity: 0;
        transform: translateX(-100px);
    }

    60%
    {
        opacity: 1;
        transform: translateX(20px);
    }

    100%
    {
        transform: translateX(0);
    }
}

@keyframes content-bounce
{
    0%
    {
        opacity: 0;
        transform: translateX(100px);
    }

    60%
    {
        opacity: 1;
        transform: translateX(-20px);
    }

    100%
    {
        transform: translateX(0);
    }
}