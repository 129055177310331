@import "src/components/timeline/TimelineVariables.scss";


.vertical-timeline-circle-image
{
    width: 50%;
    margin-top: -$spacer;
    margin-left: -$spacer;
    margin-bottom: $spacer * 2;
    position: relative;
    z-index: 1;

    > div
    {
        position: relative;
        top: $spacer;
        left: $spacer;

        > div
        {
            position: relative;
            border-radius: 50%;
            overflow: hidden;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            background: $vertical-timeline-icon-default-color;
            z-index: 10;

            > img
            {
                position: absolute;
                width: 100%;
                height: auto;
            }
        }

        &:after
        {
            position: absolute;
            content: '';
            display: block;
            top: -35%;
            bottom: -35%;
            left: -35%;
            right: -35%;
            border-radius: 50%;
            z-index: 5;
            background: rgba($vertical-timeline-icon-default-color, 0.4);
        }
    }
}