@import "src/components/timeline/TimelineVariables.scss";

.vertical-timeline-image
{
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    border-radius: 50%;
    background: white;
    overflow: hidden;

    img
    {
        width: 100%;
        height: auto;
    }
}

.vertical-timeline-row
{
    .col
    {
        align-self: center;
    }
}

.MuiAccordionDetails-root
{
    p {
        font-family: Roboto,-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif;
        font-size: 1rem;
        color: #212529;
        font-weight: 400;
        line-height: 1.5;
    }

    h4 {
        font-family: Roboto,-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Oxygen,Ubuntu,Cantarell,Droid Sans,Helvetica Neue,Arial,sans-serif;
        font-weight: 700;
    }
}

.vertical-timeline-modal-container
{
    height: 0;

    .vertical-timeline-modal-overlay
    {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 1030;
    }

    .vertical-timeline-modal
    {
    }
}

.vertical-timeline-element
{
    &:nth-child(even)
    {
        .vertical-timeline-element-content
        {
            .vertical-timeline-row
            {
                @media only screen and (min-width: $vertical-timeline-md-breakpoint)
                {
                    flex-direction: row-reverse;
                }
            }
        }
    }
}
