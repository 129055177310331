﻿@import "src/styles/shared.scss";

//Paddings of the main timeline container.
$vertical-timeline-padding-y: $spacer * 2;
$vertical-timeline-padding-x: 0;

//vertical line properties.
$vertical-timeline-color: #ec008c;
$vertical-timeline-spacer: $spacer;
$vertical-timeline-width: $spacer * 0.125;


//Margin of the element container
$vertical-timeline-item-margin-y: $spacer;
$vertical-timeline-item-margin-x: 0;

//Icon properties.
$vertical-timeline-icon-size: $spacer * 1.25;
$vertical-timeline-icon-top: -10px;
$vertical-timeline-icon-left: $vertical-timeline-spacer - ($vertical-timeline-icon-size / 2) + ($vertical-timeline-width / 2);
$vertical-timeline-icon-default-color: #000000;

//Content properties
$vertical-timeline-content-background: #808080;
$vertical-timeline-content-padding-y: $spacer / 2;
$vertical-timeline-content-padding-x: $spacer;
$vertical-timeline-content-section-spacing: $spacer * 0.125;
$vertical-timeline-content-border-radius: $spacer * 0.5;
$vertical-timeline-content-header-font-family: $font-family-h1;
$vertical-timeline-content-font-family: $font-family-h1;
$vertical-timeline-content-header-font-size: 30pt;
$vertical-timeline-content-font-size: 15pt;
$vertical-timeline-content-color: $body-color;
$vertical-timeline-content-line-height: 1.15;

    //mobile
    $vertical-timeline-content-xs-spacing: $spacer * 4;

    //tablet
    $vertical-timeline-content-xs-spacing: $spacer * 4;
    $vertical-timeline-sm-breakpoint: 576px;

    //laptop
    $vertical-timeline-content-xs-spacing: $spacer * 4;
    $vertical-timeline-md-breakpoint: 768px;

    //pc
    $vertical-timeline-content-xs-spacing: $spacer * 4;
    $vertical-timeline-lg-breakpoint: 992px;

    //large pc
    $vertical-timeline-content-xs-spacing: $spacer * 4;
    $vertical-timeline-xl-breakpoint: 1200px;


//Video Properties
$vertical-timeline-video-play-size: $spacer * 6;