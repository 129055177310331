@import "src/components/timeline/TimelineVariables.scss";

.vertical-timeline-video
{
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: $spacer;

    .vertical-timeline-video-preview
    {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        cursor: pointer;
        z-index: 10;

        img
        {
            width: 100%;
            height: auto;
        }

        &:before
        {
            font-family: 'FontAwesome';
            content: '\f04b';
            color: white;
            line-height: 1;
            text-align: center;
            font-size: $vertical-timeline-video-play-size * 0.5;
            padding-top: $vertical-timeline-video-play-size * 0.25;
            display: block;
            z-index: 3;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -($vertical-timeline-video-play-size / 2);
            margin-top: -($vertical-timeline-video-play-size / 2);
            border-radius: 50%;
            width: $vertical-timeline-video-play-size;
            height: $vertical-timeline-video-play-size;
            background: $vertical-timeline-icon-default-color;
            pointer-events: none;
        }

        &:after
        {
            content: '';
            opacity: 0;
            display: block;
            z-index: 2;
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            width: $vertical-timeline-video-play-size * 2;
            height: $vertical-timeline-video-play-size * 2;
            margin-left: -(($vertical-timeline-video-play-size * 2) / 2);
            margin-top: -(($vertical-timeline-video-play-size * 2) / 2);
            background: rgba($vertical-timeline-icon-default-color, 0.4);
            pointer-events: none;
        }

        &:hover
        {
            &:before
            {
                animation: pulse-play-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
            }

            &:after
            {
                animation: pulse-play-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
            }
        }
    }

    span
    {
        opacity: 0;
    }

    iframe
    {
        z-index: 5;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    &.video-loading
    {
        .vertical-timeline-video-preview
        {
            &:before
            {
                content: '\f021';
                animation: pulse-spin-play-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
            }

            &:after
            {
                animation: pulse-play-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
            }
        }
    }

    &.video-starting
    {
        .vertical-timeline-video-preview
        {
            &:before
            {
                content: '\f021';
                animation: pulse-spin-play-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
            }

            &:after
            {
                animation: pulse-play-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
            }
        }
    }

    &.video-playing
    {
        span
        {
            opacity: 1;
        }

        .vertical-timeline-video-preview
        {
            display: none;
        }
    }
}

@keyframes pulse-play-ring
{
    0%
    {
        opacity: 1;
        transform: scale(.33);
    }

    80%, 100%
    {
        opacity: 0;
    }
}

@keyframes pulse-play-dot
{
    0%
    {
        transform: scale(.8);
    }

    50%
    {
        transform: scale(1);
    }

    100%
    {
        transform: scale(.8);
    }
}

@keyframes pulse-spin-play-dot
{
    0%
    {
        transform: scale(.8) rotate(0deg);
    }

    50%
    {
        transform: scale(1) rotate(180deg);
    }

    100%
    {
        transform: scale(.8) rotate(360deg);
    }
}